@media screen and (min-width: 1200px) {
  .numberpanel {
    position: relative;
    width: 300px;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1200px) {
  .numberpanel {
    position: relative;
    width: 300px;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

.numberpanel-circle {
  position: relative;
  left: -50%;
  display: inline;
}

.numberpanel-numberwrapper {
  position: relative;
}

.numberpanel-number {
  position: relative;
  left: -50%;
  margin-top: -35px;
}

.numberpanel-details {
  height: 100%;
  margin-top: -20px;
  padding: 25px;
  border: 2px solid;
  border-radius: 20px;
  border-color: #D3D4D7;
}