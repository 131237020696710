@font-face {
  font-family: 'Flexo';
  src: url('https://static.prod.pokemon.com/fonts/flexo-regular.eot');
  src: url('https://static.prod.pokemon.com/fonts/flexo-regular.eot?#iefix') format('embedded-opentype'), url('https://static.prod.pokemon.com/fonts/flexo-regular.woff') format('woff'), url('https://static.prod.pokemon.com/fonts/flexo-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flexo';
  src: url('https://static.prod.pokemon.com/fonts/flexo-bold.eot');
  src: url('https://static.prod.pokemon.com/fonts/flexo-bold.eot?#iefix') format('embedded-opentype'), url('https://static.prod.pokemon.com/fonts/flexo-bold.woff') format('woff'), url('https://static.prod.pokemon.com/fonts/flexo-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Flexo';
  src: url('https://static.prod.pokemon.com/fonts/flexo-heavy.eot');
  src: url('https://static.prod.pokemon.com/fonts/flexo-heavy.eot?#iefix') format('embedded-opentype'), url('https://static.prod.pokemon.com/fonts/flexo-heavy.woff') format('woff'), url('https://static.prod.pokemon.com/fonts/flexo-heavy.ttf') format('truetype');
  font-weight: 900; /* Black */
  font-style: normal;
}

:root {
  --red: #c33527;
  --green: #4dae5b;
  --blue: #31a7d9;
  --yellow: #e6bd2f;
  --white: #fff;
  --lightest-grey: #f3f3f3;
  --lighter-grey: #a4a4a4;
  --light-grey: #9a9a9a;
  --dark-grey: #616161;
  --darker-grey: #313131;
  --darkest-grey: #212529;
  --disabled-bg: #ddd;
}

::-ms-reveal {
  display: none;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}

body #root {
  flex: auto;
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.spinner {
  display: flex;
  margin: auto;
  margin-top: 50px;
  width: 32px;
  height: 32px;
  animation: spinner 0.5s infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.title-wrapper h2 {
  font-weight: 900;
  margin: 0;
  text-align: center;
  font-size: 24px;
}

p {
  margin: 0;
  font-size: 14px;
}

.title-wrapper {
  margin-bottom: 10px;
}

.sub-title {
  margin-top: 5px;
  font-size: 14px;
}

.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body-wrapper {
  margin-left: 13px;
  margin-right: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-column {
  flex: 1;
  padding: 1em;
}

.forgotten-image {
  display: block;
  width: 100%;
}

.forgotten-text {
  top: 1.2em;
  left: 1.75em;
}

.show-error {
  display: flex;
}

.invalid-feedback {
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  color: #dc3545;
  text-align: left;
  border: none;
  background: none;
}

.hide-error {
  display: none;
}

.link,
.link a {
  color: var(--blue);
  font-weight: bold;
}

.link.functional-link a {
  text-decoration: none;
}

.link.functional-link :visited {
  color: var(--blue);
}

.link.linklike-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
}

.error-message {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14pt;
  color: #dc3545;
}

.recovery-info-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.lost-email-link {
  width: 100%;
  font-size: 12px;
  text-align: left;
  align-self: flex-end;
  text-decoration: none;
  margin-top: 20px;
}

.button-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.body-wrapper .continue-button {
  margin-top: 30px;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.copyright {
  font-size: 10px;
  color: #4e4e4e;
  text-align: center;
  white-space: pre-line;
  width: 355px;
}

li {
  margin: 10px 0;
}