.legal-footer {
  background: #333;
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
}
.legal-footer .exit-link {
  display: flex;
  /* breaks on mobile */
  min-width: 125px; 
  cursor: pointer;
}
.legal-footer .exit-link img {
  max-width: 100%;
}
.legal-footer a {
  user-select: none;
  color: white;
  text-decoration: underline;
}
.legal-footer .links-and-caru {
  flex-grow: 1;
  align-items: center;
  display: flex;
}
.legal-footer .links-and-caru .links {
  display: flex;
  flex-grow: 1;
  padding: 0 10px;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: space-evenly;
  white-space: nowrap;
}
.legal-footer .links-and-caru .links > * {
  padding: 2px;
}
.legal-footer .copyright {
  color: #aaaaaa;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
}
@media all and (max-width: 1000px) {
  .legal-footer {
    flex-shrink: 0;
    flex-direction: row;
    padding: 10px;
  }
}
@media all and (max-width: 500px) {
  .legal-footer {
    padding: 10px;
  }
  .legal-footer .exit-link {
    max-width: 200px;
  }
  .legal-footer .links-and-caru .links {
    font-size: 14px;
  }
  .legal-footer .copyright {
    font-size: 12px;
  }
}

/* make the button look like the other links */
button {
  text-decoration: underline;
}