.panel-image {
  width: 200px;
  height: 75px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.panel-details {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  width: 250px;
}

.panel-subtext {
  margin-top: 8px;
}

.basic-button.disabled {
  background-color: #4dad5b4d;
  color: #FFFFFF;
}

.panel-button {
  position: absolute;
  bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80% !important;
}

.base-container-border {
  border-radius: 0 0 15px 15px;
}

.streams-container-border {
  border-radius: 15px;
  margin: 1rem;
}

.header-image {
  z-index: 3;
}

@media only screen and (min-width: 576px) {
  .header-image {
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) {
  .header-image {
    width: 1200px;
  }
}

.profile-pic {
  border-radius: 50%;
  border: 1px solid black;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  height: 7rem;
}

.stream-link {
  cursor: pointer;
}