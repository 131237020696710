#root {
  position: relative;
}

.main-area {
  position: relative;
  padding-top: 0px !important;
  padding-bottom: 50px !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

@media screen and (min-width: 1200px) {
  .main-area {
    width: 1200px;
  }
  
  .header-area {
    width: 800px;
  }
}

@media screen and (max-width: 1200px) {
  .main-area {
    width: 75%;
  }
  
  .header-area {
    width: 75%;
  }

  .landing-grid {
    width: 100%;
    position: relative;
  }
}

.header-area {
  position: relative;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.grid-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

.link-color {
  color: #31A7D9;
}

.border-expiring-soon-red {
  
}

.expired { 
  color: 'default-grey'
}