.basic-button.disabled {
  background-color: #FFFFFF;
  color: #9A9A9A;
  border-color: #E5E7EB;
  border-style: solid;
  border-width: 1px;
}

.legal-image {
  max-width: 50% !important;
  max-height: 25% !important;
}